<template>
    <div>
        <div class="pt-10 ml-5 flex justify-end">
            <button
                v-permission="['create']"
                class="rounded-lg border-orange-500 bg-white border text-orange-500 px-3 py-2 hover:bg-orange-500 hover:text-white duration-500 tranistion"
                @click.prevent="openDialog">
                <i class="el-icon-plus text-lg"></i>新增
            </button>
        </div>
        <ul class="flex flex-1">
            <li
                v-for="(tab, index) in tabs"
                :key="index"
                :class="currentTab === tab.value ? 'bg-white text-orange-600' : 'bg-gray-400 text-gray-100'"
                class="cursor-pointer rounded-t-lg px-3 py-2"
                @click="changeTab(tab.value)">
                {{ tab.label }}
            </li>
        </ul>
        <el-dialog
            :visible.sync="showDialog"
            :close-on-click-modal="true"
            :modal-append-to-body="true"
            :close-on-press-escape="true"
            @close="closeDialog">
            <label>問題代碼（切記代碼不能重複)</label>
            <el-input v-model.trim="questionKey"></el-input>
            <div v-if="faqKeys.includes(questionKey)" class="text-red-600">重複問題代碼</div>
            <btn :disabled="faqKeys.includes(questionKey)" @onClick="addQuestion"> 新增 </btn>
            <!-- <button @click.prevent="addQuestion"
                    :disabled="faqKeys.includes(questionKey)"
                    class="blue-btn">新增</button> -->
        </el-dialog>
        <el-form ref="form" :model="form" :rules="rules">
            <div class="bg-white shadow-lg rounded-b-lg rounded-tr-lg">
                <div v-for="(question, key) in form" :key="key" class="flex border-b px-2 py-5">
                    <span
                        v-if="!defaultKeys.includes(key)"
                        v-permission="['delete']"
                        class="cursor-pointer self-center mr-2"
                        @click="deleteQuestion(key)">
                        <i class="icofont-ui-delete text-orange-600 text-2xl"></i>
                    </span>
                    <h3 class="self-center mr-2 min-w-[150px]">{{ key }}</h3>
                    <div>
                        <div>
                            <label>問題文字</label>
                            <el-input v-model="form[key].content" type="textarea"></el-input>
                        </div>
                        <div>
                            <label>連結</label>
                            <el-input v-model="form[key].link" type="text"></el-input>
                        </div>
                        <div>
                            <label>問題類型</label>
                            <el-input v-model="form[key].questionType"></el-input>
                        </div>
                    </div>
                    <div class="ml-2">
                        <div class="flex mb-2">
                            <h5>問題按鈕</h5>
                            <button
                                v-permission="['update']"
                                class="border-orange-600 text-orange-600 border w-6 h-6 rounded-full flex items-center justify-center ml-2"
                                @click.prevent="addQuestionBtn(key, form[key].buttons === undefined ? 0 : form[key].buttons.length)">
                                <font-awesome-icon :icon="['fas', 'plus']" />
                            </button>
                        </div>
                        <draggable v-model="form[key]['buttons']">
                            <div v-for="(item, index) in form[key].buttons" :key="index" class="flex items-center">
                                <label class="whitespace-nowrap mr-2">按鈕文字</label>
                                <el-input v-model="form[key]['buttons'][index].text"></el-input>
                                <label class="whitespace-nowrap mx-2">解答key</label>
                                <el-input v-model="form[key]['buttons'][index].key"></el-input>
                                <span
                                    v-permission="['update']"
                                    class="cursor-pointer self-center mr-2"
                                    @click="deleteQuestionBtn(key, index, item.text)">
                                    <i class="icofont-ui-delete text-orange-600 text-2xl"></i>
                                </span>
                            </div>
                        </draggable>
                    </div>
                </div>
            </div>
        </el-form>
        <div class="my-10 mr-5 text-right">
            <el-button v-permission="['update']" class="mb-10" type="primary" native-type="submit" @click.prevent="onSubmit('form')">
                更新
            </el-button>
        </div>
    </div>
</template>

<script>
import firebase, { firebaseConnectRef } from "@/plugins/firebase";
// 導入拖拉排放套件
import draggable from "vuedraggable";
// 導入自定義按鈕組件
import Btn from "@/components/Button.vue";
export default {
    components: {
        draggable,
        Btn,
    },
    data() {
        return {
            // 表單資料
            form: {},
            // 表單驗證規則
            rules: {},
            // 問題代碼
            questionKey: null,
            // 問題按鈕代碼
            buttonKey: null,
            // tab資料 用來區分屬於服務商或會員端的問題
            tabs: [
                { label: "服務商問題", value: "provider_questions" },
                { label: "會員問題", value: "member_questions" },
            ],
            // 預設選中tab
            currentTab: "provider_questions",
            // 判斷是否顯示彈窗
            showDialog: false,
            // 目前現有的 firebase 以設定過key
            faqKeys: [],
            // 目前現有的 問題
            defaultKeys: ["first", "contact"],
        };
    },
    methods: {
        // 新增問題
        addQuestion() {
            // 判斷是否有重複 key 重複key 則不執行
            if (this.faqKeys.includes(this.questionKey)) {
                this.$message({
                    type: "error",
                    messsage: "問與答 key 重複",
                });
                return;
            }
            // 預設表單資料
            function setDefaultData() {
                this.content = "";
                this.link = "";
                this.questionType = null;
                this.buttons = [];
            }
            const defaultData = new setDefaultData();
            //如果沒有輸入值時 不作動
            if (this.$isEmpty(this.questionKey)) {
                return;
            }
            this.$set(this.form, this.questionKey, defaultData);
            this.closeDialog();
        },
        /**
         * 新增問題可選按鈕
         * @param { type String(字串) } questionKey 問題key
         * @param { type Number(數字) } index 問題按鈕索引 用來新增問題按鈕索引
         */
        addQuestionBtn(questionKey, index) {
            // // 判斷是否有重複 key 重複key 則不執行
            // if (this.faqKeys.includes(questionKey)) {
            //     this.$message({
            //         type: "error",
            //         messsage: "問與答 key 重複"
            //     });
            //     return;
            // }
            // 判斷一開始沒有建立問題按鈕時 要修改時 會無法修改 因為沒有 key 因此多此判斷式
            if (this.form[questionKey]["buttons"] === undefined) {
                // 將對應問題新增 buttons key 且預設值為空陣列
                this.$set(this.form[questionKey], "buttons", []);
            }
            this.$set(this.form[questionKey]["buttons"], index, {
                text: "",
                key: "",
            });
        },
        // 打開彈窗
        openDialog() {
            this.showDialog = true;
        },
        // 關閉彈窗
        closeDialog() {
            this.questionKey = null;
            // 關閉彈窗
            this.showDialog = false;
        },
        /**
         * 表單發送
         * @param { type String(字串) } formName 傳送表單名稱
         * @example ref="form"
         */
        async onSubmit(formName) {
            try {
                // 等待表單驗證是否成功 try catch 會自動判斷是 true 或 false 因次不用寫 if 判斷
                await this.$refs[formName].validate();
                this.sendData();
            } catch (err) {
                this.$message({
                    type: "error",
                    message: "表單驗證失敗",
                });
            }
        },
        // 儲存與更新錯誤訊息
        async sendData() {
            try {
                // 新增與更新錯誤訊息
                await firebase
                    .database()
                    .ref(this.currentTab)
                    .set({ ...this.form });
                this.getList();
                this.$message({
                    type: "success",
                    message: "更新成功",
                });
            } catch (err) {
                console.log(err);
                this.$message({
                    type: "error",
                    message: "更新失敗",
                });
            }
        },
        // 取得列表
        async getList() {
            try {
                const data = await firebaseConnectRef(this.currentTab).get();
                // 判斷是否有訊息
                if (data.exists()) {
                    this.form = data.val();
                    // 取得目前現有問與答 key
                    this.getFaqKeys(data.val());
                }
            } catch (err) {
                this.$message({
                    type: "error",
                    message: "取得數據失敗",
                });
            }
        },
        /**
         * 取得目前現有的 問與答 key
         * @param { type Object(物件) } faqs 機器人問與答資料
         */
        getFaqKeys(faqs) {
            const keys = [];
            Object.keys(faqs).forEach((objKey) => {
                // 判斷是否有重複 key 沒有才加入陣列中
                if (!keys.includes(objKey)) {
                    keys.push(objKey);
                }
                // 判斷是否有 buttons key 有的話才執行
                // eslint-disable-next-line no-prototype-builtins
                // if (faqs[objKey].hasOwnProperty("buttons")) {
                //     faqs[objKey].buttons.forEach((item) => {
                //         // 判斷是否有重複 key 沒有才加入陣列中
                //         if (!keys.includes(item.key)) {
                //             return keys.push(item.key);
                //         }
                //     });
                // }
            });
            this.faqKeys = keys;
        },
        /**
         * 變更tab事件
         * @param { type String(字串) } tab 更換 tab值
         */
        changeTab(tab) {
            this.currentTab = tab;
            this.form = {};
            this.getList();
        },
        /**
         * 刪除問題
         * @param { type String(字串) } questionKey 刪除問題key
         */
        async deleteQuestion(questionKey) {
            try {
                await this.$confirm(`刪除此${questionKey}問題?`, "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                });
                this.$delete(this.form, questionKey);
            } catch (err) {
                console.log(err);
            }
        },
        /**
         * 刪除問題按鈕
         * @param { type String(字串) } questionKey 刪除問題key
         * @param { type Number(數字) } index 按鈕索引
         * @param { type Strng(字串) } text 按鈕文字
         */
        async deleteQuestionBtn(questionKey, index, text) {
            try {
                await this.$confirm(`刪除此"${text}"按鈕?`, "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                });
                this.form[questionKey]["buttons"].splice(index, 1);
            } catch (err) {
                console.log(err);
            }
        },
    },
    created() {
        this.getList();
    },
};
</script>
